/* global */
import "../../css/app/components/home.scss";

class Home
{
    constructor () 
    {
    }
}

new Home;